<template>
  <div class="p-5 mb-4 mt-4 bg-light rounded-3">
    <div class="container-fluid py-5">
      <h1 class="display-5 fw-bold">Сервисы</h1>
      <p class="col-md-8 fs-4">
        Здесь собраны полезные айтишные сервисы для алготрейдеров и алгоинвесторов по работе с биржами и брокерами.
        Возможно скоро сервисов будет больше.
      </p>
      <a class="btn btn-warning btn-lg" target="_blank" href="https://bit.ly/3zoU1t6" type="button">Тинькофф Инвестиции</a>
      <a class="btn btn-info btn-lg ms-2" target="_blank" href="https://accounts.binance.com/ru/register?ref=335216425" type="button">Binance</a>
    </div>
  </div>

  <div class="row align-items-md-stretch">
    <div class="col-md-6">
      <div class="h-100 p-5 text-white bg-dark rounded-3">
        <h2>Автор</h2>
        <p>
          Я давно занимаюсь веб-разработкой, интересуюсь инвестициями и трейдингом. Меня легко найти в соцсетях,
          на YouTube (основное), Telegram и на <a href="https://azzrael.ru" target="_blank" class="text-white">azzrael.ru</a> .
        </p>
        <a class="btn btn-outline-light" href="https://www.google.com/search?q=azzraelcode" target="_blank" type="button">Azzrael Code</a>
      </div>
    </div>
    <div class="col-md-6">
      <div class="h-100 p-5 bg-light border rounded-3">
        <h2>Зачем?</h2>
        <p>
          Все здесь для меня или для подписчиков. Поэтому работает как есть.
          Если что-то работает не так, есть пожелания, хочется заказать фичу или хочется задонатить на развитие...
        </p>
        <a class="btn btn-outline-secondary" type="button" href="https://azzrael.ru/spasibo" target="_blank">Пиши сюда <i class="bi bi-arrow-right"></i></a>
      </div>
    </div>
  </div>
</template>

<style>
  .splash img{
    width: 100%;
    height: auto;
  }

</style>