<template>

  <div v-if="alerts.length" class="toast-container position-fixed bottom-0 end-0 p-3" style="z-index: 11">
    <TheAlert v-for="alert in alerts" :alert="alert"/>
  </div>

  <TheNavbar />

  <div class="container">
    <router-view />
  </div>

</template>

<script>
import {mapGetters} from 'vuex'
import TheNavbar from '@/views/TheNavbar'
import TheAlert from "@/components/TheAlert";
export default {
  data() {
    return {

    }
  },
  computed: mapGetters(['alerts']),
  components: {TheNavbar, TheAlert}
}
</script>