import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist';

const vuexLocalStorage = new VuexPersist({
  key: 'vuex', // The key to store the state on in the storage provider.
  storage: window.localStorage, // or window.sessionStorage or localForage
})

export default createStore({
  state: {
    store: [],
  },
  getters: {
    alerts(state) {
      return state.store.filter(o => o.item_type === 'alert')
    },
    heats(state) {
      return state.store.filter(o => o.item_type === 'heat')
    }
  },
  mutations: {
    deleteItem(state, id) {
      state.store = state.store.filter(o => o.id !== id)
    },
    pushItem(state, item) {
      item.id = Math.random() * 1000
      state.store.push(item)
    },
  },
  actions: {
    addAlert(ctx, alert) {
      alert.item_type = 'alert'
      ctx.commit('pushItem', alert)
      setTimeout(() => ctx.commit('deleteItem', alert.id), 3000)
    },
  },
  modules: {},
  plugins: [vuexLocalStorage.plugin]
})
