import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

router.beforeEach((to, from, next) => {
  document.title = to.name;
  next();
});



createApp(App).use(store).use(router).mount('#app')
