import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {path: '/', name: 'Инвест Айтишка | Штуки для алготрейдеров и инвесторов', component: HomeView},
  {
    path: '/ti/get-figi',
    name: 'Тинькофф Инвестиции | Получить FIGI по Тикеру',
    component: () => import( '../components/TiFigi'),
  },
  {
    path: '/binance/heatmap',
    name: 'Binance Heatmap',
    component: () => import( '../components/BinanceHeatmap')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
