<template>
    <div class="toast d-block" role="alert" aria-live="assertive" aria-atomic="true">
      <div class="toast-header">
        <strong
            class="me-auto"
            :class="['', 'text-success', 'text-danger'][$attrs.alert.type]"
            v-text="['Сообщение', 'Успешно', 'Ошибка'][$attrs.alert.type]"
        ></strong>
        <button type="button" class="btn-close" aria-label="Close" @click="deleteAlert"></button>
      </div>
      <div class="toast-body">
        {{ $attrs.alert.text }}
      </div>
    </div>
</template>

<script>

export default {
  methods: {
    deleteAlert() {
      this.$store.commit('deleteItem', this.$attrs.alert.id)
    }
  },
}
</script>